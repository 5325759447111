.contact .contact-content {
  row-gap: 60px;
}
.contact .form-control {
  height: 56px;
  border: 1px solid #555;
  border-radius: 4px;
  background-color: var(--clr-black);
  width: 100%;
  margin-bottom: 0;
  color: var(--clr-white);
  font-family: inherit;
  font-size: 18px;
  padding-left: 20px;
  padding-right: 20px;
}
.contact .form-control-text {
  display: block;
  font-size: 16px;
  color: var(--clr-white);
  text-align: left;
  margin-bottom: 20px;
  margin-top: 6px;
}
.contact .submit-btn {
  background-color: var(--clr-robin-blue);
  color: var(--clr-white);
  text-transform: uppercase;
  padding: 16px 32px;
  letter-spacing: 1px;
  border-radius: 3px;
  margin-top: 10px;
}
.contact .submit-btn:hover {
  background: rgb(87, 100, 222);
  background: linear-gradient(
    45deg,
    rgba(87, 100, 222, 1) 0%,
    rgba(85, 179, 213, 1) 100%
  );
}
.contact .map-content {
  margin-top: 80px;
}

.info {
  /* margin-top: -10px; */
  margin-top: -40px;
}
.info .item {
  padding: 40px;
  cursor: pointer;
  transition: var(--transition);
  position: relative;
  border-radius: 50px 0px 50px 0px;
  /* border-radius: 50px 50px 50px 50px; */
  background-color: #00121a;
}
.info .item::after {
  border-radius: 50px 50px 50px 50px;
  position: absolute;
  content: "";
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 0%;
  height: 2px;
  background: rgb(87, 100, 222);
  background: linear-gradient(
    45deg,
    rgba(87, 100, 222, 1) 0%,
    rgba(85, 179, 213, 1) 100%
  );
  transition: var(--transition);
}
.info .item:hover::after {
  width: 85%;
}
.info .item-info-text {
  /* font-size: 25px; */
  font-size: 30px;
  letter-spacing: 2px;
}
.info .item-icon {
  font-size: 45px;
}
.info .item-list {
  row-gap: 30px;
}
.info .item-link {
  margin-top: 18px;
  display: inline-block;
  transition: var(--transition);
}
.info .item:hover .item-link {
  color: var(--clr-white);
}

@media screen and (min-width: 768px) {
  .info .item-list {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 30px;
  }
}

@media screen and (min-width: 1200px) {
  .info .item-list {
    /* grid-template-columns: repeat(3, 1fr); */
    /* column-gap: 30px; */
    column-gap: 55px;
  }
}

@media screen and (min-width: 992px) {
  .contact .contact-content {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 40px;
    row-gap: 0;
    align-items: center;
  }
}
