.mb {
  margin-bottom: 15px;
}
.numbers {
  display: grid;
  grid-gap: 150px;
  grid-template-columns: repeat(3, 1fr);
  align-items: start;
  padding: 50px;
}
.inner-wrapper {
  padding: 0 50px;
}
.numbers-value {
  display: flex;
  justify-content: center;
  width: 100%;
  border-bottom: 1px solid;
  font-size: 130px;
  font-weight: 600;
  /* letter-spacing: -0.05em; */
  line-height: 1;
  align-items: center;
  display: flex;
  overflow: hidden;
  padding-bottom: 20px;
  /* margin: 20px 0 0; */
}
.n-value {
  font-size: 120px;
}
.numbers-title {
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 20px 0 0;
}
.n-tittle {
  /* font-size: 25px; */
  font-size: 30px;
}
.large-heading-2 {
  width: 100%;
  color: #fff;
  /* font-size: 3.375rem; */
  font-size: 4.975rem;

  line-height: 45px;
  margin-bottom: 10px;
}
.large-heading-3 {
  width: 100%;
  color: #fff;
  /* font-size: 3.975rem; */
  font-size: 5.975rem;

  line-height: 45px;
  margin-bottom: 10px;
}
@media screen and (max-width: 1100px) {
  .numbers {
    grid-gap: 100px;
    grid-template-columns: repeat(1, 1fr);
    align-items: center;
    justify-content: center;
  }
  .numbers-value {
    width: 100%;
    border-bottom: 1px solid;
    font-size: 130px;
    font-weight: 600;
    line-height: 1;
    align-items: center;
    display: flex;
    overflow: hidden;
    padding-bottom: 20px;
    justify-content: center;
  }
  .n-value {
    font-size: 100px;
  }
  .n-tittle {
    /* font-size: 20px; */
    font-size: 30px;
    text-align: center;
  }
  .flnos {
    align-items: center;
    justify-content: center;
  }
}
