.section-p-service {
  padding: 3rem 0;
}
.item-title-ser {
  margin: 22px 0;
  /* font-size: 28px; */
  font-size: 36px;
}
.text-ser {
  letter-spacing: 1px;
  line-height: 1.55;
  font-weight: 300;
  /* font-size: 19px; */
  font-size: 24px;
  color: var(--clr-grey);
  font-weight: 400;
  margin-bottom: 20px;
}
.box-item {
  display: inline-block;
  padding: 1px 15px;
  /* border: 2px solid var(--clr-robin-blue); */
  box-shadow: 0 0 3px #c5d9ec;
  /* border-radius: 50px 0 50px 0; */
  border-radius: 15px 0 15px 0;
  margin: 8px 11px;
}
.mt {
  margin-top: 15px;
}
.services .item {
  box-shadow: 0 0 15px #047df4; /* White glow */

  /* margin-top: 60px; */
  margin-bottom: 60px;
  padding: 35px;
  cursor: pointer;
  transition: var(--transition);
  position: relative;
  border-radius: 50px 0 50px 0;
  max-width: 1200px;
  grid-column-gap: 0px;
  background-color: #00121a;
  border: 1px solid rgba(90, 90, 90, 0.21);
  grid-template-rows: auto;
  grid-template-columns: 2.5fr 1.5fr;
  align-content: center;
  align-items: center;
  /* padding: 50px 20px 20px 60px; */
  /* box-shadow: 0 0 75px rgba(0, 115, 255, 0.1); */
}
.services .item::after {
  border-radius: 0 0 0px 0;
  position: absolute;
  content: "";
  bottom: 0;
  left: 45%;
  transform: translateX(-50%);
  width: 0%;
  height: 3px;
  background: rgb(87, 100, 222);
  background: linear-gradient(
    45deg,
    rgba(87, 100, 222, 1) 0%,
    rgba(85, 179, 213, 1) 100%
  );
  transition: var(--transition);
}
.services .item:hover::after {
  /* width: 100%; */

  width: 90%;
}
.services .item:hover .item-text {
  color: var(--clr-white);
}
.services .item-icon {
  font-size: 45px;
}
.services .item-list {
  row-gap: 30px;
}
.services .item-link {
  margin-top: 18px;
  display: inline-block;
  transition: var(--transition);
}
.services .item:hover .item-link {
  color: var(--clr-white);
}
/* /////////////////////////////// */
.gradient-border-div {
  /* background-image: linear-gradient(231deg, #02c6c3, #047df4 53%, #02c6c3); */
  box-shadow: 0 0 45px rgba(0, 166, 255, 0.18);
}
.grid-6._6-design {
  /* grid-column-gap: 0px; */
  background-color: #00121a;
}
/* /////////////////////////////// */

@media screen and (min-width: 768px) {
  .services .item-list {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 30px;
  }
}

@media screen and (min-width: 1200px) {
  .services .item-list {
    grid-template-columns: repeat(3, 1fr);
    column-gap: 30px;
  }
}
