.flex-q-r {
  display: flex;
  align-items: center;
}
.item {
  /* box-shadow: 0 0 15px #047df4; */
  /* margin-top: 60px; */
  margin-top: 40px;
  padding: 10px 30px;
  cursor: pointer;
  transition: var(--transition);
  position: relative;
  /* border-radius: 50px 0 50px 0; */
  max-width: 1200px;
  grid-column-gap: 0px;
  /* background-color: #00121a; */
  /* border: 1px solid rgba(90, 90, 90, 0.21); */
  grid-template-rows: auto;
  grid-template-columns: 2.5fr 1.5fr;
  align-content: center;
}

.border-shadow {
  border-radius: 50px 0 50px 0;
  box-shadow: 0 0 15px #047df4;
}
.border-rad-tl {
  border-radius: 50px 0 0 0;
}
.border-rad-tr {
  border-radius: 0 50px 0 0;
}
.border-rad-br {
  border-radius: 0 0 50px 0;
}
.border-rad-bl {
  border-radius: 0 0 0 50px;
}

.qualities .section-t {
  /* margin-bottom: 15px; */
  margin-bottom: 25px;
}
.qualities .item-icon {
  margin-right: 28px;
  font-size: 46px;
}
.qualities .item-title {
  margin-top: 0;
}
.qualities .item-list {
  row-gap: 30px;
}
.qualities-content {
  row-gap: 60px;
}
/* ////////////////// */
.content-left {
  align-items: center;
}
.who-is-tabs {
  /* max-width: 60rem; */
  /* margin: 0 auto; */
  margin: 0 0;

  /* box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2); */
  /* border-radius: 1.25rem; */
  color: rgb(255, 255, 255);
  width: 49%;
}
.who-is-tabs-1 {
  /* max-width: 60rem; */
  /* margin: 0 auto; */
  margin: 0 0;

  /* box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2); */
  /* border-radius: 1.25rem; */
  color: rgb(255, 255, 255);
  width: 49%;
  border-right: 5px solid rgba(90, 90, 90, 0.21);
}
.who-is-tabs-nav {
  display: flex;
  /* justify-content: center; */
  justify-content: space-around;
  /* border-bottom: 1px solid #ffffff; */
  margin-bottom: 20px;
}
.who-is-btn {
  border-bottom: 1px solid #ffffff;
  padding: 1rem 1.25rem;
  line-height: 3.9375rem;
  font-weight: 700;
  /* font-size: 2.325rem; */
  font-size: 2.825rem;

  width: 50%;

  text-align: center;
}
.who-is-tabs-main {
  display: flex;
  gap: 25px;
}

.who-is-item-bord-r {
  /* border-right: 1px solid #ffffff; */
  padding: 10px;
}
.who-tab-inf {
  flex: 0 0 calc(50% - 1.25rem);
}
.who-tab-title {
  margin-bottom: 1.875rem;
  /* font-size: 1.75rem; */
  font-size: 2.1rem;
  line-height: 1.2;
  font-weight: 700;
  color: rgb(254, 254, 254);
}
.who-tab-desc {
  margin-bottom: 1em;
  color: rgb(203, 208, 213);
}
.who-tab_list {
  margin-bottom: 1.875rem;
  padding-left: 1.25rem;
  list-style: disc;
  font-weight: 700;
}
ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  /* padding-inline-start: 40px; */
  padding-inline-start: 20px;
}
li {
  display: list-item;
  text-align: -webkit-match-parent;
}
/* ////////////////// */

@media screen and (min-width: 992px) {
  .qualities-content {
    /* grid-template-columns: repeat(2, 1fr); */
    align-items: center;
    column-gap: 60px;
    justify-content: center;
  }
}

@media screen and (max-width: 740px) {
  .services .item-list {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 30px;
  }
  .who-is-tabs-1 {
    width: 90%;
    border-right: 0px solid rgba(90, 90, 90, 0.21);
    border-bottom: 5px solid rgba(90, 90, 90, 0.21);
  }
  .who-is-tabs {
    width: 90%;
    border-right: 0px solid rgba(90, 90, 90, 0.21);
  }
  .border-rad-tr {
    border-radius: 0px 0 0 0;
  }
  .flex-q-r {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
