.features .item-icon {
  transition: var(--transition);
}
.features .item-head {
  border-radius: 50px 0 50px 0;
  padding: 18px 28px;
  cursor: pointer;
}
.features .item-head-border {
  border-bottom: 2px solid var(--clr-robin-blue);
}
.features .item-body {
  /* margin-top: 16px; */
  max-height: 0;
  overflow: hidden;
  transition: all 500ms linear;
}
.features .item-body .text {
  letter-spacing: 1px;
  padding-bottom: 16px;
}
.features .item-body .text {
  padding-bottom: 16px;
}
.features .item-body-show {
  /* max-height: 200px; */
  max-height: 1250px;
}
.features .item-icon-rotate {
  transform: rotate(45deg);
}
/* /////////////////////// */
.contaier-f {
  max-width: 35.5rem;
}
.large-heading-2 {
  width: 100%;
  color: #fff;
  font-size: 3.375rem;
  line-height: 45px;
  margin-bottom: 10px;
}
.large-heading-3 {
  width: 100%;
  color: #fff;
  font-size: 3.975rem;
  line-height: 45px;
  margin-bottom: 10px;
}
.centered-intro-2 {
  /* max-width: 39.5rem; */
  max-width: 46rem;

  grid-column-gap: 1.5rem;
  grid-row-gap: 0.3125rem;
  text-align: center;
  flex-direction: column;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  justify-content: center;
  align-items: center;
  justify-items: center;
  margin-bottom: 2.8125rem;
  margin-left: auto;
  margin-right: auto;
  display: grid;
}
/* /////////////////////// */
