.section-t-header {
  font-size: 44px;
  color: var(--clr-white);
  padding: 20px 15px;
  position: relative;
  line-height: 1.2;
  justify-content: center;
}
.line-separator {
  /* height: 460px;  */
  height: 500px;

  width: 5px;
  background: rgb(87, 100, 222);
  background: linear-gradient(
    45deg,
    rgba(87, 100, 222, 1) 0%,
    rgba(85, 179, 213, 1) 100%
  );
  margin: 0 30px;
  position: absolute;
  /* top: 5px; */
  left: 90%;
  transform: translateX(-50%);
}

.header {
  min-height: 100vh;
  background: linear-gradient(rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9)),
    url("../../assets/images/header_main_img.jpg") center/cover no-repeat;
}
.header-content {
  /* width: 100%;
  min-width: 100%; */
  max-width: 920px;
  /* max-width: 1350px; */
  display: flex; /* Make the container a flex container */
  justify-content: space-between; /* Distribute space between child elements */
}
.header-content .text-lead {
  margin: 3.2rem 0 4.9rem 0;
  /* /////////////////// */
  /* width: 100%; */
  width: 85%;
}
.header-title {
  /* font-size: 50px; */
  font-size: 65px;
  font-weight: 800;
  /* line-height: 1.2; */
  line-height: 1.1;

  background-color: white;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  width: 95%;
}
.header-title-span {
  /* font-size: 50px; */
  font-size: 75px;
  font-weight: 800;
  line-height: 1.2;
  /* background-color: white; */
  background: linear-gradient(
    to right,
    var(--clr-robin-blue),
    var(--clr-crazy-blue)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.header-btn {
  align-self: center !important;
}
.header-btn span {
  margin-left: 10px;
}
.header-btns {
  /* width: 100%; */
  width: 231px;
  /* margin-top: 2.4rem; */
}
.header-btns button {
  padding: 10px;
  border: 2px solid var(--clr-robin-blue);
  transition: var(--transition);
}
.header-btns button span {
  margin-left: 8px;
  color: white;
}

@media screen and (min-width: 779px) {
  .header-title {
    /* font-size: 65px; */
    font-size: 80px;
  }
  .header-content {
    /* max-width: 920px; */
    max-width: 1040px;
  }
  .header-title-span {
    /* font-size: 75px; */
    font-size: 90px;
  }
}
@media screen and (max-width: 783px) {
  .line-separator {
    height: 540px;
  }
}
@media screen and (max-width: 779px) {
  .line-separator {
    /* height: 390px; */
    height: 490px;
  }
}
@media screen and (max-width: 620px) {
  .header-title {
    /* font-size: 35px; */
    font-size: 55px;
  }
  .header-title-span {
    /* font-size: 45px; */
    font-size: 65px;
  }
  .header-content .text-lead {
    margin: 2.2rem 0 2.2rem 0;
  }
  .line-separator {
    /* height: 390px; */
    height: 425px;
  }
}
@media screen and (max-width: 500px) {
  .header-title {
    /* font-size: 30px; */
    font-size: 50px;
  }
  .header-title-span {
    /* font-size: 40px; */
    font-size: 60px;
  }
  .header-content .text-lead {
    margin: 2.2rem 0 2.2rem 0;
  }
  .line-separator {
    /* height: 440px; */
    /* height: 400px; */
    height: 405px;
  }
}
@media screen and (max-width: 462px) {
  .header-title {
    font-size: 45px;
  }
  .header-content .text-lead {
    margin: 2.2rem 0 2.2rem 0;
  }
  .line-separator {
    height: 435px;
  }
}
@media screen and (max-width: 400px) {
  .header-title {
    /* font-size: 30px; */
    font-size: 45px;
  }
  .header-content .text-lead {
    margin: 2.2rem 0 2.2rem 0;
  }
  .line-separator {
    height: 430px;
  }
}
@media screen and (max-width: 364px) {
  .line-separator {
    height: 480px;
  }
}
@media screen and (max-width: 344px) {
  .line-separator {
    height: 530px;
  }
}
